import axios from 'axios'
import { 
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    // PRODUCT_LISTWITHOUTPAGE_REQUEST,
    // PRODUCT_LISTWITHOUTPAGE_SUCCESS,
    // PRODUCT_LISTWITHOUTPAGE_FAIL,
    myCATEGORY_REQUEST,
    myCATEGORY_SUCCESS,
    myCATEGORY_FAIL,
    
    CATEGORY_REQUEST,
    CATEGORY_SUCCESS,
    CATEGORY_FAIL,

    CATEGORY_ThroREQUEST,
    CATEGORY_ThroSUCCESS,
    CATEGORY_ThroFAIL,
    
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAIL,

    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAIL,

    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_FAIL,

    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_CREATE_REVIEW_FAIL,

    PRODUCT_TOP_REQUEST,
    PRODUCT_TOP_SUCCESS,
    PRODUCT_TOP_FAIL,
   
  
 } from '../constants/productConstants'

export const listProducts = (keyword='') => async(dispatch)=>{

        try{
            dispatch({type:PRODUCT_LIST_REQUEST})
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/products${keyword}`)
            // const {data} = await axios.get('http://localhost:8000/api/products${keyword}')  
            dispatch({
                type:PRODUCT_LIST_SUCCESS,
                payload:data
            })

        }catch(error){
            dispatch({
                type:PRODUCT_LIST_FAIL,
                payload:error.response && error.response.data.detail
                    ?error.response.data.detail
                    :error.message,
            })
        }
 }

//  export const listProductwithoutPage = (keyword='') => async(dispatch)=>{

//     try{
//         dispatch({type:PRODUCT_LISTWITHOUTPAGE_REQUEST})
//         const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/listProducts${keyword}`) 
//         // const {data} = await axios.get('http://localhost:8000/api/products/listProducts${keyword}')  
//         dispatch({
//             type:PRODUCT_LISTWITHOUTPAGE_SUCCESS,
//             payload:data
//         })

//     }catch(error){
//         dispatch({
//             type:PRODUCT_LISTWITHOUTPAGE_FAIL,
//             payload:error.response && error.response.data.detail
//                 ?error.response.data.detail
//                 :error.message,
//         })
//     }
// }

export const myCategory = (searchWord) => async(dispatch)=>{

    try{
        dispatch({type:myCATEGORY_REQUEST})
        console.log("myCategory", searchWord);
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/categoryProducts${searchWord}/`
        ) 
        dispatch({
            type:myCATEGORY_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:myCATEGORY_FAIL,
            payload:error.response && error.response.data.detail
                ?error.response.data.detail
                :error.message,
        })
    }
}

export const categoryPage = () => async(dispatch)=>{

    try{
        dispatch({type:CATEGORY_REQUEST})
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/categoryBlouse/`) 
        dispatch({
            type:CATEGORY_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:CATEGORY_FAIL,
            payload:error.response && error.response.data.detail
                ?error.response.data.detail
                :error.message,
        })
    }
}

export const categoryThrowOn = () => async(dispatch)=>{

    try{
        dispatch({type:CATEGORY_ThroREQUEST})
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/categoryThrowOn/`) 
        dispatch({
            type:CATEGORY_ThroSUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:CATEGORY_ThroFAIL,
            payload:error.response && error.response.data.detail
                ?error.response.data.detail
                :error.message,
        })
    }
}

 export const listProductDetails = (id) => async(dispatch)=>{

    try{
        dispatch({type:PRODUCT_DETAILS_REQUEST})
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}`) 
        dispatch({
            type:PRODUCT_DETAILS_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:PRODUCT_DETAILS_FAIL,
            payload:error.response && error.response.data.detail
                ?error.response.data.detail
                :error.message,
        })
    }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/products/delete/${id}/`,
            config
        )

        dispatch({
            type: PRODUCT_DELETE_SUCCESS,
            //payload: data
        })


    } catch (error) {
        dispatch({
            type: PRODUCT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createProduct = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/products/create/`,
            //because we not sending data from our form, we leave it empty
            {},
            config
        )

        dispatch({
            type: PRODUCT_CREATE_SUCCESS,
            //we want to pass it in our reducer state, so we want to pass in data
            payload: data
        })



    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateProduct = (product) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `${process.env.REACT_APP_API_URL}/api/products/update/${product._id}/`,
            //because we not sending data from our form, we leave it empty
            product,
            config
        )

        dispatch({
            type: PRODUCT_UPDATE_SUCCESS,
            //we want to pass it in our reducer state, so we want to pass in data
            payload: data
        })
        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createProductReview = (productId,review) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/products/${productId}/reviews/`,
            //because we not sending data from our form, we leave it empty
            review,
            config
        )

        dispatch({
            type: PRODUCT_CREATE_REVIEW_SUCCESS,
            //we want to pass it in our reducer state, so we want to pass in data
            payload: data
        })
    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listTopProducts = (keyword='') => async(dispatch)=>{

    try{
        dispatch({type:PRODUCT_TOP_REQUEST})
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/top/`) 
        dispatch({
            type:PRODUCT_TOP_SUCCESS,
            payload:data
        })

    }catch(error){
        dispatch({
            type:PRODUCT_TOP_FAIL,
            payload:error.response && error.response.data.detail
                ?error.response.data.detail
                :error.message,
        })
    }
}