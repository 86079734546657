import React, { useEffect, useState} from 'react';
import { Image } from 'react-bootstrap'
import axios from "axios";
// import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
import {useDispatch,useSelector} from 'react-redux'
// import ImageGallery from 'react-image-gallery';
// import '../image-gallery.css';
import Loader from './Loader'
import Message from './Message'
import {listTopProducts} from '../actions/productActions'
//import images from './images';

// const images = [
//     {
//       // original: 'https://picsum.photos/id/1018/1000/400/',
//       original: '/images/product_dress02.jpg',
//       // thumbnail: 'https://picsum.photos/id/1018/250/150/',
//       thumbnail:'/images/product_dress02.jpg',
//     },
//     {
//       // original: 'https://picsum.photos/id/1015/1000/400/',
//       // thumbnail: 'https://picsum.photos/id/1015/250/150/',
//       original:'/images/throw_on01.jpg',
//       thumbnail:'/images/throw_on01.jpg',

//     },
//     {
//       // original: 'https://picsum.photos/id/1026/1000/400/',
//       // thumbnail: 'https://picsum.photos/id/1026/250/150/',
//       original:'/images/shop5.jpg',
//       thumbnail: '/images/shop5.jpg',
//     }
//   ];
// const ImageSlider = () => {
//   const productTopRated = useSelector(state => state.productTopRated)
//   const {error,loading,products} =productTopRated
//     return ( 
//         <div className="wrapper">
//             <ImageGallery items={products.filter((product)=> product.category==='Dress')} />
//         </div>
//      );
// }
 
// export default ImageSlider;
// const ImageSlider = () => {
//   const dispatch = useDispatch();
//   const[images,setImages] = React.useState(null);
//   useEffect(()=>{
//     let  shouldCancel = false;
//     const call = async(id) =>{
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/api/products/${id}`
//       );
//       if(!shouldCancel && response.data && response.data.length>0){
//         setImages(
//           response.data.map(url=>({
//             original: `${url}=w1024`,
//             thumbnail: `${url}=w100`
//           }))
//         )
//       }
//     };
//     call();
//     return () =>(shouldCancel = true);
//   },[])
//  return images?<ImageGallery items={images}/>:null;
// }
 
// export default ImageSlider;
const ImageSlider = () => {
  const productDetails = useSelector(state => state.productDetails)
  const {error,loading,product} = productDetails
  const prd = product.image
  // const [SelectedImg,setSelectedImg] =useState(prd);
  // const [images, setImages] = useState([])

  // setImages([prd, ...product.productimages])
  const [SelectedImg,setSelectedImg] =useState(prd); 
  //console.log("=========",SelectedImg, "=============")
  // const [SelectedImg,setSelectedImg] =useState(images[0]);
  // useEffect((product) => {
  //   setSelectedImg([...SelectedImg,product])
    
  // }, [product,SelectedImg]);
  // console.log(product)
  useEffect(
    ()=>{
      console.log('Trying to reload the image selected')
      console.log(SelectedImg)
      
    }
  )
  return ( 
      <div className='product_Images'>
        { loading?<Loader/> :error?<Message variant='danger'>{error}</Message>:
        (
          <div>
          <img src={SelectedImg} alt='Selected' className='selected'/>
          
            {/* <div className='product_img'>
              { product.productimages?.map((img)=>(
                <img style={{border:SelectedImg === img ?"4px solid red" : ''}}
                key={img.id}
                src = {[img]}
                alt ='dog'
                onClick={()=>setSelectedImg(img)}
                />
              ))}
            </div> */}
            <div className='product_img'>
              {
              product.productimages?.map((img)=>(
                  <div>
                  <img src={[img.image]} alt='dog' style={{border: SelectedImg === img.image ?"2px solid red":''}}
                  key={img.id}
                  onClick={()=>setSelectedImg(img.image)}/>
                  </div>
                ))
              }
  
            </div>
          </div>
        )}
        {/* <img src={SelectedImg} alt='Selected' className='selected'/>
        <div className='product_img'>
          {images.map((img,index)=>(
            <img style={{border: SelectedImg === img ?"4px solid red" : ''}}
             key={index} 
             src={[img]} 
             alt='dog'
             onClick={()=>setSelectedImg(img)}
             /> 
          ))}
        </div> */}
      </div>  
  );
}
 
export default ImageSlider;