import React ,{useState}from 'react'
import { Button,Form,Col,Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

function SearchBox2() {
    const [keyword,setKeyword] = useState('')
    let history = useHistory()

    const submitHandler =(e)=>{
        e.preventDefault()
        if(keyword){
            history.push(`/?keyword=${keyword}&page=5`)
        }else{
            //when a user is on a different page, we send him to that original path 
            history.push(history.push(history.location.pathname))
        }
    }
  return (
     <div className="hero__search__form">
        <form onSubmit={submitHandler}>
            <div className="hero__search__categories">
                All Categories
                <span className="arrow_carrot-down"></span>
            </div>
            <input type="text" placeholder="Search for what you need" name='q' onChange={e=>setKeyword(e.target.value)}/>
            <button type="submit" className='site-btn'>SEARCH</button>
        </form>
     </div>
  )
}

export default SearchBox2