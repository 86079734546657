import React from 'react'

export default function aboutScreen() {
  return (
    <div>
      <div className="container">
        <div className="row"> 
           <p>Hello there this page is under development</p>
        </div>
        </div>       
    </div>
  )
}

