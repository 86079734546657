import React,{useState,useEffect, Children} from 'react'
import { Link } from 'react-router-dom'
import { Row,Col,Image,ListGroup,Button,Card,Form } from 'react-bootstrap'
import Rating from '../components/Rating'
// import products from '../products'
// import axios  from 'axios'
import { useDispatch,useSelector  } from 'react-redux'
import { listProductDetails,createProductReview} from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import ImageSlider from '../components/image-slider';

function ProductScreenLate({match,history}) {
    // const product = products.find((p) => p._id == match.params.id) 
    // const [product,setProduct] =useState([]);
    const [qty,setQty]= useState(1)

    const [rating,setRating]= useState(0)
    const [comment,setComment]= useState('')

    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)
    const {error,loading,product} =productDetails

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} =userLogin

    const productReviewCreate = useSelector(state => state.productReviewCreate)
    const {error:errorProductReview,
           loading:loadingProductReview,
            success:successProductReview
          }=productReviewCreate
    
    useEffect(() =>{
        // async function fetchProduct(){
        //     const {data} = await axios.get(`/api/product/${match.params.id}`)
        //     setProduct(data)
        // }
        // fetchProduct()

        if(successProductReview){
            setRating(0)
            setComment('')
            dispatch({type:PRODUCT_CREATE_REVIEW_RESET})
        }
        dispatch(listProductDetails(match.params.id))
    },[dispatch,match,successProductReview])
    const addToCartHandler =() =>{
        // console.log('Add to Cart',match.params.id)
        history.push(`/cart/${match.params.id}?qty=${qty}`)
    }

    const submitHandler=(e)=>{
        e.preventDefault()
        dispatch(createProductReview(
            match.params.id,{
                rating,
                comment
            }
        ))
    }
    return (
      <div >
            <div className="container"> 
        
            <Link to='/' className='btn btn-light my-3'>Go Back </Link>
            {
                loading ?
                <Loader/>
                : error
                ? <Message variant='danger'>{error}</Message>
                :(
                    <div>
                        <Row>
                            <Col md={6}>  
                             <ImageSlider/>
                              {/* <Image src={product.image} alt={product.name} fluid className='selected'/>  */}
                              {/* <div className='product_img'>
                                {product.productimages.map((prd)=>(
									<div className='product-img' key={prd.id}>
											<Image src={prd.image} alt='' fluid/>
									</div>
								
					            ))}  
                                </div>*/}	
                            </Col>
                            <Col md={6}>
                            <div className="product-details">
							<h2 className="product-name">{product.name}</h2>
                            
							<div>
								<div className="product-rating">
                                    <Rating value={product.rating} text={`${product.numReviews} Reviews`} color={'#f8e825'} />
								</div>
								{/* <a className="review-link" href="#">10 Review(s) | Add your review</a> */}
							</div>
							<div>
								<h3 className="product-price">{product.price}/= </h3>
                               
								<span className="product-available">{product.countInStock >0 ?'In Stock' : 'Out of Stock'}</span>
							</div>
							<p>{product.description}.</p>

							<div className="product-options">
								<label>
									Size
									<select className="input-select">
										<option value="0">X</option>
									</select>
								</label>
								<label>
									Color
									<select className="input-select">
										<option value="0">Red</option>
									</select>
								</label>
							</div>

							<div className="add-to-cart">
								<div className="qty-label">
									
                                    {product.countInStock > 0 && (
                            
                                <Row>
                                    <Col>QTY</Col>
                                    <Col xs='auto' className='my-0'>
                                    <Form.Control
                                        as="select"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                    >
                                        {
                                            [...Array(product.countInStock).keys()].map((x)=>(
                                                <option key={x+1} value={x+1}>
                                                    {x+1}
                                                </option>
                                            ))
                                        }
                                    </Form.Control>
                                    </Col>
                                </Row>  
                                )}
								</div>
								<button className="add-to-cart-btn"
                                    onClick = {addToCartHandler}
                                    disabled={product.countInStock==0} 
                                ><i className="fa fa-shopping-cart"></i> add to cart</button>

							</div>
							<ul className="product-links">
								<li>Share:</li>
								<li><a href="#"><i className="fa fa-facebook"></i></a></li>
								<li><a href="#"><i className="fa fa-twitter"></i></a></li>
								<li><a href="#"><i className="fa fa-google-plus"></i></a></li>
								<li><a href="#"><i className="fa fa-envelope"></i></a></li>
							</ul>
                            
						</div>
                        
                            </Col>    
                        </Row>
                        <div className="col-md-12">
						    <div id="product-tab">
                            <ul className="tab-nav">
								<li className="active"><a data-toggle="tab" href="#tab1">Reviews </a></li>
							</ul>
                            <div className="tab-content">
                                <div id="tab1" className="tab-pane fade in active">
                                                        

                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                )

            }
          
            </div>

           
            </div> 
    )
}

export default ProductScreenLate
