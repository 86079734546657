import React from 'react'

function Rating({value,text,color}) {
    return (
        <div className="product-rating">
            <span>
                <i style={{ color:'#D10024' }} className={
                    value >=1
                    ? 'fa fa-star'
                    : value >=0.5
                        ? 'fa fa-star-half-alt'
                        : 'fa fa-star-o' 
                }>
                </i>
            </span>
            <span>
                <i style={{ color:'#D10024' }} className={
                    value >=2
                    ? 'fa fa-star'
                    : value >=1.5
                        ? 'fa fa-star-half-alt'
                        : 'fa fa-star-o' 
                }>
                </i>
            </span>
            <span>
                <i style={{ color:'#D10024' }} className={
                    value >=3
                    ? 'fa fa-star'
                    : value >=2.5
                        ? 'fa fa-star-half-alt'
                        : 'fa fa-star-o' 
                }>
                </i>
            </span>
            <span>
                <i style={{ color:'#D10024' }} className={
                    value >=4
                    ? 'fa fa-star'
                    : value >=3.5
                        ? 'fa fa-star-half-alt'
                        : 'fa fa-star-o' 
                }>
                </i>
            </span>
            <span>
                <i style={{ color:'#D10024'}} className={
                    value >=5
                    ? 'fa fa-star'
                    : value >=4.5
                        ? 'fa fa-star-half-alt'
                        : 'fa fa-star-o' 
                        // fas fa-star
                        // far fa-star
                }>
                </i>
            </span>
            <div className='rating-link'>
                <span>
                    {/* {text && text} */}
                    <a className="review-link" href="#">{text && text}(s) </a>
                </span>
            </div>
            
        </div>
    )
}

export default Rating
