// import { Container } from 'react-bootstrap'
import { BrowserRouter as Router,Routes,Route,Switch } from 'react-router-dom'
import Header from './components/Header'
import menu_wrapper from './components/menu_wrapper'
import Footer from './components/Footer'
import HomeScreens from './screens/HomeScreens'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen' 
import ShippingScreen from './screens/ShippingScreen' 
import PaymentScreen from './screens/PaymentScreen' 
import PlaceOrderScreen from './screens/PlaceOrderScreen' 
import OrderScreen from './screens/OrderScreen' 
import UserListScreen from './screens/UserListScreen' 
import UserEditScreen from './screens/UserEditScreen' 
import ProductListScreen from './screens/ProductListScreen' 
import ProductEditScreen from './screens/ProductEditScreen'
import BlouseCategoryScreen from './screens/BlouseCategoryScreen'
import ThrowOnCategoryScreen from './screens/ThrowOnCategoryScreen'
import AllCategoryScreen from './screens/AllCategoryScreen'
import DressScreen from './screens/DressScreen'
import T_shirtScreen from './screens/T_shirtScreen'
import OrderListScreen from './screens/OrderListScreen'
import aboutScreen from './screens/aboutScreen'
import ProductScreen2 from './screens/ProductScreen2'
import ProductScreenLate from './screens/ProductScreenLate'  
// import "./assets/css/style.css"
// import "./assets/css/owl.carousel.min.css"
// import "./assets/css/jquery-ui.min.css"
// import "./assets/css/nice-select.css"
// import "./assets/css/elegant-icons.css"
// import "./assets/css/font-awesome.min.css"
// import "./assets/css/bootstrap.min.css"

function App() {
  return (
    <Router>
      <Switch>
      <div className='MainDiv '>
      <menu_wrapper/>
      <Header/>
      {/* <main className="py-3"> */}
        {/* <Container> */}
          {/* <HomeScreens/> */}
          <Route path='/' component={HomeScreens} exact/>
          <Route path='/login' component={LoginScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/profile' component={ProfileScreen} />
          {/*<Route path='/products/:id' component={ProductScreen}/>*/} 
          <Route path='/products/:id' component={ProductScreenLate}/>
          <Route path='/cart/:id?' component={CartScreen}/>
          <Route path='/shipping' component={ShippingScreen}/>
          <Route path='/payment' component={PaymentScreen}/> 
          <Route path='/placeorder' component={PlaceOrderScreen}/> 
          <Route path='/order/:id' component={OrderScreen}/> 

          <Route path='/admin/userlist' component={UserListScreen}/> 
          <Route path='/admin/user/:id/edit' component={UserEditScreen}/> 

          <Route path='/admin/productlist' component={ProductListScreen}/>
          <Route path='/admin/product/:id/edit' component={ProductEditScreen}/> 

          <Route path ='/categoryBlouse' component={BlouseCategoryScreen}/>
          <Route path ='/categoryThrowOn' component={ThrowOnCategoryScreen}/>

          <Route path='/categoryProducts' component={AllCategoryScreen}/>
          <Route path ='/categoryDress' component={DressScreen}/>
          
          <Route path='/categoryTshirts' component={T_shirtScreen}/>
          <Route path='/admin/orderlist' component={OrderListScreen}/> 

          <Route path='/about_us'component={aboutScreen}/>
          <Footer/>
          </div> 
        {/* </Container> */}
      {/* </main> */}
      
      
      </Switch>
    </Router>
  );
}

export default App;
