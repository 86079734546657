import React,{useState,useEffect}from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {Table,Button} from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {listUsers,deleteUser} from '../actions/userActions'

function UserListScreen({history}) {

    const  dispatch = useDispatch()
    
    const userList = useSelector(state => state.userList)
    const {loading,error, users } =userList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } =userLogin

    const userDelete = useSelector(state => state.userDelete)
    const { success:successDelete } =userDelete

    useEffect(() => {
        if(userInfo && userInfo.isAdmin){
            dispatch(listUsers()) 
        }else{
            history.push('/login')
        }
      
        
    }, [dispatch,history,successDelete,userInfo])

    const deletehandler =(id) =>{
        //console.log('DELETE',id)
        if(window.confirm('Are you sure you want to delete this user?')){
            dispatch(deleteUser(id))
        }
        
    }
    return (
        <section className="page-section text-white mb-0" >
        <div className="container">
            <h2 className='bg-secondary'>USERS</h2>
            {loading
            ?(<Loader/>)
            : error
            ?(<Message variant='danger'>{error}</Message>)
            :(
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>ADMIN</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user=>(
                            <tr key={user._id}>
                                <td>{user._id}</td>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.isAdmin ?(
                                    <i className='fa fa-check' style={{color:'green'}}></i>
                                ):(
                                    <i className='fa fa-check' style={{color:'red'}}></i>
                                )}</td>
                                <td>
                                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                                        <Button variant='light' className='btn-sm'>
                                        <i className='fa fa-edit' ></i>
                                        </Button>
                                    </LinkContainer>
                                    <Button variant='danger' className='btn-sm' onClick={()=> deletehandler(user._id)}>
                                        <i className='fa fa-trash' ></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    </section>
    )
}

export default UserListScreen
