import React,{useState,useEffect}from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {Table,Button,Row,Col} from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import  Paginate from '../components/Paginate'
import {listProducts,deleteProduct,createProduct, listProductDetails} from '../actions/productActions'
import { PRODUCT_CREATE_RESET, PRODUCT_LIST_RESET, PRODUCT_LIST_SUCCESS } from '../constants/productConstants'
function ProductListScreen({history,match}) {

    const  dispatch = useDispatch()
    
    const productList = useSelector(state => state.productList)
    const {loading,error, products,page,pages} =productList
    //we are doing this because we want to go back to the list of products after deleting
    const productDelete = useSelector(state => state.productDelete)
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = productDelete

    const productCreate = useSelector(state => state.productCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate,product:CreatedProduct } = productCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } =userLogin

    let keyword = history.location.search
    useEffect(() => {
        dispatch({type:PRODUCT_CREATE_RESET})
        if(!userInfo.isAdmin){
            history.push('/login')
        }

        if(successCreate){
            history.push(`/admin/product/${CreatedProduct._id}/edit`)
        }else{
            dispatch(listProducts(keyword))
        }
      
        
    }, [dispatch,history,userInfo,successDelete,successCreate,createProduct,keyword])

    const deleteHandler =(id) =>{
        //console.log('DELETE',id)
        if(window.confirm('Are you sure you want to delete this product?')){
            //delete products
            dispatch(deleteProduct(id))
        }
        
    }
    const createProductHandler =() =>{
        dispatch(createProduct())
    }
    return (
        <section className="page-section  text-white mb-0" >
        <div className="container">
            <Row className='align-items-center'>
                <Col className='bg-secondary'>
                    <h3>Products</h3>
                </Col>

                <Col className='text-center'>
                    <Button className='my-3' onClick={createProductHandler}>
                        <i className='fa fa-plus'></i> Create Product
                    </Button>
                </Col>
            </Row>
            {loadingDelete && <Loader/>}
            {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

            {loadingCreate && <Loader/>}
            {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

            {loading
            ?(<Loader/>)
            : error
            ?(<Message variant='danger'>{error}</Message>)
            :(
                <div>
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NAME</th>
                            <th>PRICE</th>
                            <th>CATEGORY</th>
                            <th>BRAND</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map(product=>(
                            <tr key={product._id}>
                                <td>{product._id}</td>
                                <td>{product.name}</td>
                                <td> Shs {product.price}</td>
                                <td>{product.category}</td>
                                <td>{product.brand}</td>
                                <td>
                                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                                        <Button variant='secondary' className='btn-sm'>
                                        <i className='fa fa-edit' ></i>
                                        </Button>
                                    </LinkContainer>
                                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(product._id)}>
                                                    <i className='fa fa-trash'></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Paginate page={page} pages={pages} isAdmin={true} />
                </div>
             )}
        </div>
        </section>
    )
}

export default ProductListScreen
